/* eslint-disable indent */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import kebabCase from 'lodash/kebabCase'
import { PrismicRichText } from '@prismicio/react'

import { theme } from '../../styles'
import Text from '../common/Text'
import Button from '../common/Button'
import Space from '../common/Space'
import getImage from '../../utils/getImage'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'
import { useUserLang } from '../../context/UserLangContext'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    padding-top: ${p => (p.hasTopPadding ? '48px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '48px' : '0px')};
  }

  @media (max-width: 800px) {
    padding-top: ${p => (p.hasTopPadding ? '40px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '40px' : '0px')};
  }

  .rich-text-container p {
    margin-bottom: 0px;
  }
`

const BG = styled.div`
  position: absolute;
  width: 500vw;
  height: 100%;
  margin-left: -300%;
  top: 0;
  z-index: -1;
  background-color: ${p => p.theme.color[p.bgColor]};

  @media (max-width: 1050px) {
    margin-left: -100%;
  }
`

const Header = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`

const TabWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  z-index: 2;
  &:focus {
    outline: none;
  }

  @media (min-width: 1050px) {
    ${p =>
      p.vertical &&
      `
    display: flex;
    gap: 64px;
  `}
  }

  @media (max-width: 1050px) {
    margin-top: 24px;
  }

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`

const TabButtonWrapper = styled.div`
  width: 100%;
  justify-content: center;
  gap: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 16px;

  .rich-text-container p {
    margin-bottom: 0px;
  }

  @media (min-width: 1050px) {
    ${p =>
      p.vertical &&
      `
        flex-direction: column;
        flex: 1;
        padding: 40px 32px;
        background-color: ${p.theme.color[p.tabCardColor]};
        border-radius: 16px;
        gap: 24px;
        justify-content: flex-start;
        height: fit-content;
    `}
  }

  @media (max-width: 1050px) {
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 16px 0px 0px 0px;
    flex-wrap: wrap;
  }
`

const TabButton = styled.button`
  ${p =>
    !p.vertical &&
    css`
      display: flex;
      flex: 1;
      max-width: 195px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 8px 24px;
      background-color: ${theme.color[p.tabColor]};
      border-radius: 24px;
      position: relative;

      &:focus {
        border-radius: 24px;
        background-color: ${theme.color.purple500};
        border: 3px solid ${theme.color.purple500};
        p {
          color: ${theme.color.white};
        }
      }

      ${p.selected &&
      `
        background-color: ${theme.color.purple500};
        border: 3px solid ${theme.color.purple500};
        box-shadow: none;
        p {
          color: ${theme.color.white};
        }
      `}
    `}

  @media (min-width: 1050px) {
    ${p =>
      p.vertical &&
      css`
        display: block;
        transition: all 50ms ease-in-out;
        background-color: transparent;
        padding: 8px;

        &:hover {
          border-left: 4px solid ${p.theme.color.purple500};
        }

        &:focus {
          border-radius: 0px;
        }

        p {
          transition: all 50ms ease-in-out;
          color: ${p.theme.color.gray500};
          font-family: ${p.theme.fonts.family.bodyMedium};
          font-size: ${p.theme.fonts.size.bodyMedium};
          font-weight: ${p.theme.fonts.weight.bodyMedium};
          line-height: ${p.theme.fonts.lineHeight.bodyMedium};
          letter-spacing: ${p.theme.fonts.letterSpacing.bodyMedium};

          &:hover,
          &:focus {
            color: ${p.theme.color.offBlack};
            font-family: ${p.theme.fonts.family.bodyBold};
            font-size: ${p.theme.fonts.size.bodyBold};
            font-weight: ${p.theme.fonts.weight.bodyBold};
          }
        }

        ${p.selected &&
        `
          border-left: 4px solid ${p.theme.color.purple500};
          padding-left: 16px;
          p {
              font-family: ${p.theme.fonts.family.bodyBold};
              font-weight: ${p.theme.fonts.weight.bodyBold};
              color: ${theme.color.purple500};
          }
        `}
      `}
  }

  &:focus {
    outline-offset: 4px;
    outline: 3px solid ${theme.color.purple500}!important;
    border-radius: 16px;
    border: none;
  }

  @media (max-width: 1050px) {
    min-width: 150px;
    display: flex;
    flex: 1;
    max-width: 195px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px 24px;
    background-color: ${p => p.theme.color[p.tabColor]};
    border-radius: 24px;
    position: relative;

    &:focus {
      border-radius: 24px;
    }

    p {
      text-align: center;
    }

    ${p =>
      p.selected &&
      `
        background-color: ${theme.color.purple500};
        border: 3px solid ${theme.color.purple500};
        box-shadow: none;
        p {
          color: ${theme.color.white};
        }
      `}
  }
`

const TabButtonText = styled(Text)`
  p {
    ${props =>
      props.lang !== 'en' &&
      `
      word-break: break-word;
      hyphens: auto;
    `}
  }

  @media (max-width: 500px) {
    p {
      font-size: ${p => p.theme.fonts.size.smallBody}!important;
    }
  }
`

const TitleText = styled(Text)`
  ${p =>
    p.coloredText &&
    `
    &:after {
      content: '${p.coloredText}';
      color: ${theme.color.purple500};
    }
  `}
`

const TabPanelWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${p => p.theme.color[p.panelColor]};
  align-items: center;
  padding: 64px 16px 0px 16px;
  border: 3px solid transparent;
  gap: 40px;

  @media (min-width: 1050px) {
    ${p =>
      p.vertical &&
      `
    flex: 3;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px;
  `}
  }

  &:focus {
    outline: ${theme.focusOutline};
  }

  ${p =>
    !p.selectedTab &&
    css`
      height: 0px !important;
      min-height: 0px;
      visibility: hidden;
    `}

  @media (max-width: 1350px) {
    min-height: 425px;
  }

  @media (max-width: 1050px) {
    padding: 0px;
    padding-top: 32px;
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: 800px) {
    min-height: 350px;
  }
`

const PanelTextWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;

  ul {
    text-align: left;
    padding-left: 20px;
  }
  .rich-text-container p {
    margin-bottom: 0px;
  }

  @media (max-width: 1050px) {
    align-items: center;
    padding: 24px;
    gap: 16px;

    div[role='heading'] * {
      text-align: center;
    }
  }
`

const CheckMarkListWrapper = styled(Text)`
  ul {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});
  }

  @media (max-width: 800px) {
    li {
      margin-bottom: 16px;
    }
  }
`

const PanelImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const PanelImage = styled.img``

const modifyColors = bgColor => {
  switch (bgColor) {
    case 'offWhite':
      return {
        backgroundColor: 'offWhite',
        tabColor: 'white',
      }
    case 'white':
      return {
        backgroundColor: 'white',
        tabColor: 'offWhite',
      }
    default:
      return {
        backgroundColor: 'white',
        tabColor: 'offWhite',
      }
  }
}

const TabButtonComponent = ({ tabTitle, selectedTab, setSelectedTab, tabItems, tabColor, index, vertical }) => {
  const tabTitleText = kebabCase(tabTitle.props.field[0].text)
  const isSelected = selectedTab === tabTitleText
  const { userLang } = useUserLang()

  return (
    <TabButton
      vertical={vertical}
      selected={isSelected}
      onClick={() => setSelectedTab(tabTitleText)}
      role="tab"
      aria-selected={isSelected}
      id={tabTitleText}
      aria-controls={`${tabTitleText}-panel`}
      tabIndex={isSelected ? null : -1}
      tabColor={tabColor}
      onKeyDown={e => {
        if (e.key === 'ArrowRight' && index < tabItems.length - 1) {
          const nextEl = e.target.nextElementSibling
          if (nextEl) {
            nextEl.focus()
          }
        }
        if (e.key === 'ArrowLeft' && index > 0) {
          const prevEl = e.target.previousElementSibling
          if (prevEl) {
            prevEl.focus()
          }
        }
      }}
    >
      <TabButtonText center={!vertical} font="bodyBold" selected={isSelected} richText lang={userLang}>
        {tabTitle}
      </TabButtonText>
    </TabButton>
  )
}

const TabPanelComponent = ({
  tabTitle,
  tabPanelTitle,
  tabPanelText,
  tabPanelCheckmarkList,
  tabPanelCtaText,
  tabPanelCtaLink,
  tabPanelImage,
  selectedTab,
  backgroundColor,
  vertical,
}) => {
  const tabTitleText = kebabCase(tabTitle.props.field[0].text)
  const isSelected = selectedTab === tabTitleText
  let anchorFound = tabPanelCtaText && tabPanelCtaLink?.url

  tabPanelText?.props?.field.forEach(current => {
    current?.spans?.forEach(currentSpan => {
      if (currentSpan?.type === 'hyperlink') {
        anchorFound = true
      }
    })
  })

  return (
    <TabPanelWrapper
      selectedTab={isSelected}
      id={`${tabTitleText}-panel`}
      role="tabpanel"
      aria-labelledby={tabTitleText}
      tabIndex={anchorFound ? -1 : 0}
      hidden={!isSelected}
      panelColor={backgroundColor}
      vertical={vertical}
      className="focus-outline"
    >
      <PanelImageWrapper>{getImage(tabPanelImage, PanelImage, { noAlt: true })}</PanelImageWrapper>
      <PanelTextWrapper>
        <Text font="heading3" role="heading" aria-level="3" richText>
          {tabPanelTitle}
        </Text>
        <Text font="body" richText>
          {tabPanelText}
        </Text>
        {tabPanelCheckmarkList && (
          <>
            <CheckMarkListWrapper font="bodyBold" richText>
              {tabPanelCheckmarkList}
            </CheckMarkListWrapper>
            <Space height={24} />
          </>
        )}
        {tabPanelCtaText && tabPanelCtaLink?.url && (
          <Button text={tabPanelCtaText} to={tabPanelCtaLink.url} buttonType="link CTA" />
        )}
      </PanelTextWrapper>
    </TabPanelWrapper>
  )
}

const FeaturedTabs = ({ slice: { primary, items } }) => {
  const largeCapsText = primary.large_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const title = primary.title1.richText?.[0]?.text && primary.title1.text
  const coloredText = primary.colored_text.richText?.[0]?.text && primary.colored_text.text
  const subtitle = primary.subtitle.richText?.[0]?.text && <PrismicRichText field={primary.subtitle.richText} />
  const verticalTabListHeading = primary.vertical_tab_list_heading.richText?.[0]?.text && (
    <PrismicRichText field={primary.vertical_tab_list_heading.richText} />
  )
  const buttonText = primary.button_text.richText?.[0]?.text && primary.button_text.text
  const buttonLink = primary?.button_link.url
  const { color, vertical } = primary
  const anchorId = primary.anchor_id
  const hasTopPadding = primary?.has_top_padding
  const hasBottomPadding = primary?.has_bottom_padding
  const tabs = items.map(item => kebabCase(item.tab_title.text))
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const modifiedColor = modifyColors(color)

  return (
    <Wrapper
      id={anchorId || null}
      hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
    >
      <Header>
        {largeCapsText && (
          <Text center font="largeCaps" richText color="purple500">
            {largeCapsText}
          </Text>
        )}
        {title && (
          <TitleText heading2 center coloredText={coloredText || null}>
            {title}
          </TitleText>
        )}
        <Space height={16} />
        {subtitle && (
          <>
            <Text font="body" center richText>
              {subtitle}
            </Text>
            <Space height={0} tabletHeight={24} />
          </>
        )}
        {buttonText && buttonLink && <Button text={buttonText} to={buttonLink} buttonType="link CTA" />}
      </Header>
      {vertical && (
        <Text font="heading4" richText color="gray900" className="hide-desktop" style={{ marginTop: '24px' }}>
          {verticalTabListHeading}
        </Text>
      )}
      <TabWrapper vertical={vertical}>
        <TabButtonWrapper role="tablist" vertical={vertical} tabCardColor={modifiedColor.tabColor}>
          {vertical && (
            <Text font="heading4" richText color="gray900" className="hide-mobile hide-tablet">
              {verticalTabListHeading}
            </Text>
          )}
          {items &&
            items.map((item, i) => (
              <TabButtonComponent
                index={i}
                tabItems={items}
                tabTitle={<PrismicRichText field={item.tab_title.richText} />}
                id={kebabCase(item.tab_title.text)}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabColor={modifiedColor.tabColor}
                key={item.tab_title.text}
                vertical={vertical}
              />
            ))}
        </TabButtonWrapper>
        {items &&
          items.map(item => (
            <React.Fragment key={item.tab_title.text}>
              <TabPanelComponent
                tabTitle={<PrismicRichText field={item.tab_title.richText} />}
                tabPanelTitle={<PrismicRichText field={item.tab_panel_title.richText} />}
                tabPanelText={<PrismicRichText field={item.tab_panel_text.richText} />}
                tabPanelCheckmarkList={
                  item.checkmark_list.richText?.[0]?.text && <PrismicRichText field={item.checkmark_list.richText} />
                }
                tabPanelCtaText={item.panel_cta.text}
                tabPanelCtaLink={item.panel_cta_link}
                tabPanelImage={item.tab_panel_image}
                selectedTab={selectedTab}
                backgroundColor={modifiedColor.backgroundColor}
                vertical={vertical}
              />
            </React.Fragment>
          ))}
      </TabWrapper>
      <BG
        className="bg"
        bgColor={modifiedColor.backgroundColor}
        hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      />
    </Wrapper>
  )
}

export default FeaturedTabs

export const query = graphql`
  fragment FeaturedTabs on PrismicPageDataBodyFeaturedTabs {
    ...SliceBase
    items {
      tab_panel_image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      tab_panel_text {
        ...TextContent
      }
      tab_panel_title {
        ...TextContent
      }
      tab_title {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      panel_cta {
        ...TextContent
      }
      panel_cta_link {
        ...LinkContent
      }
    }
    primary {
      anchor_id
      has_top_padding
      has_bottom_padding
      color
      vertical
      large_caps_text {
        ...TextContent
      }
      vertical_tab_list_heading {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
      title1 {
        ...TextContent
      }
      colored_text {
        ...TextContent
      }
      button_text {
        ...TextContent
      }
      button_link {
        ...LinkContent
      }
    }
  }
`
